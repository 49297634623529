



























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { getNameByEmail } from '@/utils/users';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) setFixHeight?: boolean;

  @Prop({ type: Object, required: false }) tasq?: any;

  get routeQueryType() {
    return this.$route.query.type;
  }

  get isWorkTicketActive(): boolean {
    return false;
  }

  get tagClasses() {
    if (this.tasq.status === 'Blocked') {
      return 'bg-red-100 text-red-600';
    }
    if (this.tasq.status === 'Complete') {
      return 'bg-green-100 text-green-600';
    }
    if (this.tasq.status === 'InProgress') {
      return 'bg-purple-100 text-purple-600';
    }
    return 'bg-blue-100 text-blue-600';
  }

  fullName() {
    // @ts-ignore
    if (this.tasq?.reassignedUserName) {
      // @ts-ignore
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      // @ts-ignore
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
    // @ts-ignore
	  return getNameByEmail(this.tasq?.username);
  }

  async created() {
    //   accountModule.getReassignmentList();
  }

  goToWorkTicketPage() {
    this.$router.push({
      name: 'Tasqs',
      params: {
        id: this.tasq?.id || '',
      },
      query: { type: 'workticket', view: 'well' },
    });
    tasqsListModule.setActiveWorkTicket(this.tasq);
    // tasqsListModule.setActiveTasq(this.tasq.id);
  }
}
