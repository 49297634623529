import { render, staticRenderFns } from "./WorkTicketItem.vue?vue&type=template&id=a4706a40&scoped=true&"
import script from "./WorkTicketItem.vue?vue&type=script&lang=ts&"
export * from "./WorkTicketItem.vue?vue&type=script&lang=ts&"
import style0 from "./WorkTicketItem.vue?vue&type=style&index=0&id=a4706a40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4706a40",
  null
  
)

export default component.exports